import {ThemeProvider} from 'common/theme/ThemeProvider';
import {ApiClientProvider} from 'common/apiClient/ApiClientProvider';
import {ReactNode} from 'react';
import {Provider as JotaiProvider} from 'jotai';
import {ErrorBoundary} from './errorTracker/errrorTracker';
import {FontProvider} from './app/FontProvider';

const providers = [
  ErrorBoundary,
  ThemeProvider,
  ApiClientProvider,
  JotaiProvider,
  FontProvider,
] as const;

export const CompositeProvider = ({children}: {children: ReactNode}) => (
  <>
    {providers.reduceRight(
      (children, Provider) => (
        <Provider>{children}</Provider>
      ),
      children
    )}
  </>
);
