import {Center, Heading, Text, Link, Stack} from '@chakra-ui/react';
import {pagesPath} from 'lib/$path';
import NextLink from 'next/link';
import {useTranslation} from 'next-i18next';
import {MdHome} from 'react-icons/md';

type Props = {
  code: '404' | '403' | '500';
};
export const ErrorPage = ({code}: Props) => {
  const {t} = useTranslation();
  return (
    <Center h="60vh">
      <Stack gap="30px">
        <Center>
          <Heading fontSize="200px" as="h1" color="color.hambleA11y">
            {code}
          </Heading>
        </Center>
        <Center>
          <Text>
            {code === '403'
              ? t('403.このページへのアクセス権限がありません。')
              : code === '404'
              ? t('404.お探しのページは存在しません。')
              : code === '500'
              ? '不明なエラーが発生しました。'
              : null}
          </Text>
        </Center>
        <Center>
          <NextLink href={pagesPath.$url()} passHref legacyBehavior>
            <Link display="flex" gap="4px" alignItems="center">
              <MdHome size="30px" />
              <Text as="span" fontSize="23px" fontWeight="bold">
                {t('404.トップへ戻る')}
              </Text>
            </Link>
          </NextLink>
        </Center>
      </Stack>{' '}
    </Center>
  );
};
