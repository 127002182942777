import {ApolloError} from '@apollo/client';

const Error403 = '403 Forbidden';
const Error500 = '500 Internal Server Error';
const Error500m = /500 Internal Server Error: (.+)/;

export const isForbidden = (error?: ApolloError) => {
  return error?.message === Error403;
};

export const isInternalServerError = (error?: ApolloError) => {
  if (!error) return false;
  return error.message === Error500 || Error500m.test(error.message);
};
