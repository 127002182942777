import * as React from 'react';
import type {SVGProps} from 'react';
const SvgIconUserAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    {...props}
  >
    <path d="m12.58 20.43-.06.04c-.26.15-.77.15-1.03 0l-6.55-3.78c-.26-.15-.52-.59-.52-.89V8.23c0-.3.25-.74.51-.89l6.56-3.78c.13-.08.32-.11.51-.11s.39.04.52.11l6.55 3.78c.26.15.52.59.52.89v5.46c.55.34 1.02.77 1.4 1.29V8.23c0-.8-.52-1.71-1.22-2.11l-6.55-3.78c-.69-.4-1.74-.4-2.43 0L4.23 6.12c-.69.4-1.21 1.31-1.21 2.11v7.57c0 .8.52 1.71 1.22 2.11l6.55 3.78c.35.2.78.3 1.22.3.44 0 .87-.1 1.22-.3l.21-.12c-.34-.33-.61-.72-.85-1.13l-.01-.01Z" />
    <path d="M15.32 12.58c-.83-.48-1.95-.73-3.32-.73-1.37 0-2.48.24-3.32.73-.92.53-1.41 1.32-1.41 2.29v1.38c0 .31.25.57.57.57 0 0 2.02.03 4.16.03h.04c.09-.41.25-.79.43-1.16-1.56 0-3.22 0-4.05-.01v-.82c0-.55.27-.96.84-1.29.66-.38 1.58-.57 2.74-.57 1.09 0 1.96.18 2.61.51.41-.22.84-.39 1.3-.49-.17-.16-.37-.31-.59-.44ZM12 5.72c-.75 0-1.4.27-1.93.8s-.8 1.18-.8 1.93.27 1.4.8 1.93 1.18.8 1.93.8 1.4-.27 1.93-.8.8-1.18.8-1.93-.27-1.4-.8-1.93-1.18-.8-1.93-.8Zm1.12 3.85c-.62.62-1.62.62-2.24 0-.31-.31-.46-.68-.46-1.12 0-.44.15-.81.46-1.12.31-.31.68-.46 1.12-.46.44 0 .81.15 1.12.46.31.31.46.67.46 1.12 0 .45-.15.81-.46 1.12ZM21.12 21.29l-1.6-1.57c.34-.5.55-1.11.55-1.76 0-1.72-1.4-3.12-3.12-3.12-1.72 0-3.12 1.4-3.12 3.12a3.124 3.124 0 0 0 4.88 2.58l1.6 1.58.81-.82v-.01Zm-6.14-3.33c0-1.08.88-1.97 1.97-1.97 1.09 0 1.97.88 1.97 1.97 0 1.09-.88 1.97-1.97 1.97-1.09 0-1.97-.88-1.97-1.97Z" />
  </svg>
);
export default SvgIconUserAnalytics;
