import {inputAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const amethyst = definePartsStyle({
  addon: {
    background: 'bgcolor.versatile',
    border: '1px solid',
    borderColor: '#C4C4C4',
    color: 'color.hambleA11y',
  },
  field: {
    fontSize: 'text2',
    height: '40px',
    color: 'color.regular',
    border: '1px solid',
    borderColor: '#C4C4C4',
    padding: '1px 11px',
    _hover: {
      borderColor: '#E5E5E5',
    },
    _focusVisible: {
      padding: '0px 10px',
      borderWidth: '2px',
      borderColor: 'amethyst.regular',
    },
    _invalid: {
      padding: '0px 10px',
      border: '2px solid #ED4337',
    },
    _placeholder: {
      color: 'color.hambleA11y',
    },
  },
});

const pill = definePartsStyle({
  field: {
    fontSize: '13px',
    color: 'color.regular',
    border: '1px solid',
    rounded: 'full',
    borderColor: 'color.grayout',
    background: 'bgcolor.versatile',
    padding: '1px 11px',
    _focusVisible: {
      padding: '0px 10px',
      borderWidth: '2px',
      borderColor: 'amethyst.regular',
    },
    _invalid: {
      border: '2px solid #ED4337',
      padding: '0px 10px',
    },
    _placeholder: {
      color: 'color.hambleA11y',
    },
    height: '40px',
  },

  addon: {
    rounded: 'full',
    border: '1px solid',
    borderColor: 'color.grayout',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'bgcolor.versatile',
  },
});

const pillAmethyst = definePartsStyle({
  field: {
    ...pill.field,
    borderColor: 'amethyst.regular',
    color: 'amethyst.regular',
    background: 'white',
  },
  addon: {
    ...pill.addon,
    borderColor: 'amethyst.regular',
    color: 'amethyst.regular',
    background: 'white',
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: {amethyst, pill, pillAmethyst},
  defaultProps: {
    variant: 'amethyst',
  },
});
