type Env = {
  ENV: 'local' | 'development' | 'staging' | 'production' | 'demo';
  TOURMALINE_ENDPOINT: string;
  LP_URL: string;
  BUGSNAG_API_KEY: string;
  GTM_CONTAINER_ID: string | null;
  AMETHYST_DATA_CLOUD_PROJECT_ID: string;
  AMETHYST_SERVICE_ACCOUNT: string;
  paymentLinks: {
    global: {
      solo: {
        monthly: string;
        annual: string;
      };
      soloGuest: {
        monthly: string;
        annual: string;
      };
      inHouse: {
        monthly: string;
        annual: string;
      };
      agency: {
        monthly: string;
        annual: string;
      };
    };
  };
};

// for canary deploy
const branchName = process.env.NEXT_PUBLIC_BRANCH_NAME;
const isCanaryDeploy = !!branchName;

const env = (process.env.NEXT_PUBLIC_ENV as Env['ENV']) || 'local';
const Envs: Record<Env['ENV'], Env> = {
  local: {
    ENV: 'local',
    TOURMALINE_ENDPOINT: 'http://localhost:8080/',
    LP_URL: 'https://amethyst-lp-dev.web.app/',
    BUGSNAG_API_KEY: 'null',
    GTM_CONTAINER_ID: 'null',
    AMETHYST_DATA_CLOUD_PROJECT_ID: 'jade-tools-dev',
    AMETHYST_SERVICE_ACCOUNT: 'crawler@jade-tools-dev.iam.gserviceaccount.com',
    paymentLinks: {
      global: {
        agency: {
          monthly: 'https://buy.stripe.com/test_7sI3dCg9x5Y8bU4aFb',
          annual: 'https://buy.stripe.com/test_4gw5lKaPdcmwf6g5kQ',
        },
        inHouse: {
          monthly: 'https://buy.stripe.com/test_aEU29ycXl9ak3ny6oY',
          annual: 'https://buy.stripe.com/test_dR6cOcaPdgCMcY828K',
        },
        solo: {
          monthly: 'https://buy.stripe.com/test_5kA7tS2iHfyIcY800E',
          annual: 'https://buy.stripe.com/test_00g4hG2iHaeo8HS4gW',
        },
        soloGuest: {
          monthly: 'https://buy.stripe.com/test_00g29y9L94U47DObJw',
          annual: 'https://buy.stripe.com/test_5kA01q0az3Q07DO4h6',
        },
      },
    },
  },
  development: {
    ENV: 'development',
    TOURMALINE_ENDPOINT: isCanaryDeploy
      ? `https://${branchName}-tourmaline-dtbopqyaia-an.a.run.app/`
      : 'https://dev.api.amethy.st/',
    LP_URL: 'https://amethyst-lp-dev.web.app/',
    BUGSNAG_API_KEY: '7d86b576b478b2a6379e919f1c9a7052',
    GTM_CONTAINER_ID: 'null',
    AMETHYST_DATA_CLOUD_PROJECT_ID: 'jade-tools-dev',
    AMETHYST_SERVICE_ACCOUNT: 'jade-tools-dev.iam.gserviceaccount.com',
    paymentLinks: {
      global: {
        agency: {
          monthly: 'https://buy.stripe.com/test_14k3dC4qPdqAcY8cNh',
          annual: 'https://buy.stripe.com/test_6oE3dC4qP86gf6g9B8',
        },
        inHouse: {
          monthly: 'https://buy.stripe.com/test_00g29y9L986g9LW3cL',
          annual: 'https://buy.stripe.com/test_aEUcOc9L9cmw4rC7t3',
        },
        solo: {
          monthly: 'https://buy.stripe.com/test_14k3dC1eD4U49LWbJl',
          annual: 'https://buy.stripe.com/test_cN2dSg5uTeuEbU47t7',
        },
        soloGuest: {
          monthly: 'https://buy.stripe.com/test_28o5lKg9xgCM6zK9Bp',
          annual: 'https://buy.stripe.com/test_8wM8xW4qP0DO8HScND',
        },
      },
    },
  },
  staging: {
    ENV: 'staging',
    TOURMALINE_ENDPOINT: isCanaryDeploy
      ? `https://${branchName}-tourmaline-yoesdyxhka-an.a.run.app/`
      : 'https://stg.api.amethy.st/',
    LP_URL: 'https://amethy.st/',
    BUGSNAG_API_KEY: '7d86b576b478b2a6379e919f1c9a7052',
    GTM_CONTAINER_ID: 'GTM-MNZ3BMP',
    AMETHYST_DATA_CLOUD_PROJECT_ID: 'amethyst-data',
    AMETHYST_SERVICE_ACCOUNT: 'retriever@amethyst-data.iam.gserviceaccount.com',
    paymentLinks: {
      global: {
        agency: {
          monthly: 'https://buy.stripe.com/14k28g3GtfNMdaw3cG',
          annual: 'https://buy.stripe.com/4gwfZ63Gt4544E03cH',
        },
        inHouse: {
          monthly: 'https://buy.stripe.com/7sIdQY0uh3108Ug14C',
          annual: 'https://buy.stripe.com/4gw8wE7WJatsdaw6oX',
        },
        solo: {
          monthly: 'https://buy.stripe.com/aEUcMU90NeJIfiEbJk',
          annual: 'https://buy.stripe.com/bIY14c6SFatsb2oaFr',
        },
        soloGuest: {
          monthly: 'https://buy.stripe.com/6oE5ks1ylcBA5I4bJq',
          annual: 'https://buy.stripe.com/cN24go0uh9pob2oeVE',
        },
      },
    },
  },
  production: {
    ENV: 'production',
    TOURMALINE_ENDPOINT: 'https://api.amethy.st/',
    LP_URL: 'https://amethy.st/',
    BUGSNAG_API_KEY: '7d86b576b478b2a6379e919f1c9a7052',
    GTM_CONTAINER_ID: 'GTM-MNZ3BMP',
    AMETHYST_DATA_CLOUD_PROJECT_ID: 'amethyst-data',
    AMETHYST_SERVICE_ACCOUNT: 'retriever@amethyst-data.iam.gserviceaccount.com',
    paymentLinks: {
      global: {
        agency: {
          monthly: 'https://buy.stripe.com/bIYbIQ7WJ9podaw4gI',
          annual: 'https://buy.stripe.com/6oEbIQ1yldFE5I48wZ',
        },
        inHouse: {
          monthly: 'https://buy.stripe.com/28o008a4RfNMdawaFa',
          annual: 'https://buy.stripe.com/cN2cMUgtf454b2obJf',
        },
        solo: {
          monthly: 'https://buy.stripe.com/fZe0086SF4544E028I',
          annual: 'https://buy.stripe.com/00g5ks90NgRQfiEaFq',
        },
        soloGuest: {
          monthly: 'https://buy.stripe.com/cN29AI1ylfNM6M87tb',
          annual: 'https://buy.stripe.com/9AQ0081yl7hgdawcNx',
        },
      },
    },
  },
  demo: {
    ENV: 'demo',
    TOURMALINE_ENDPOINT: 'https://api.amethy.st/',
    LP_URL: 'https://amethy.st/',
    BUGSNAG_API_KEY: '7d86b576b478b2a6379e919f1c9a7052',
    GTM_CONTAINER_ID: 'null',
    AMETHYST_DATA_CLOUD_PROJECT_ID: 'amethyst-data',
    AMETHYST_SERVICE_ACCOUNT: 'retriever@amethyst-data.iam.gserviceaccount.com',
    paymentLinks: {
      // NOTE: Demo payment links are not set.
      global: {
        agency: {
          monthly: '',
          annual: '',
        },
        inHouse: {
          monthly: '',
          annual: '',
        },
        solo: {
          monthly: '',
          annual: '',
        },
        soloGuest: {
          monthly: '',
          annual: '',
        },
      },
    },
  },
};
export const Env = Envs[env];
