import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {SideNavigationOrgFragment} from 'lib/gql/generated';
import {useState} from 'react';
import {MdArrowRight, MdLink} from 'react-icons/md';
import NextLink from 'next/link';
import {pagesPath} from 'lib/$path';
import {decodeId} from 'common/convertId';
import {useQueryParamOrgId} from 'features/org/store';
import {useRouter} from 'next/router';
import {atomWithStorage} from 'jotai/utils';
import {useAtom} from 'jotai';
import {useTranslation} from 'next-i18next';
import {IconSessionExplorer, IconUserAnalytics} from 'common/icons';
import {BetaFeatureTag} from 'common/BetaFeatureTag';

type Props = {
  projects: SideNavigationOrgFragment['userAnalyticsProjects'];
};

const isOpenAtom = atomWithStorage('userAnalyticsProjects', true);

export const UserAnalyticsProjects = ({projects}: Props) => {
  const [isOpen, setIsOpen] = useAtom(isOpenAtom);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const [isHover, setIsHover] = useState(false);
  const {orgDatabaseId} = useQueryParamOrgId();
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const uaProjects = projects.filter(
    p => p.__typename === 'UserAnalyticsProject'
  );
  const count = uaProjects.length;

  if (!orgDatabaseId) return null;

  return (
    <Stack spacing="2px">
      <Button
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={isOpen ? onClose : onOpen}
        size="sm"
        leftIcon={
          <Box w="18px" h="18px">
            {isHover ? (
              <MdArrowRight
                size="100%"
                style={{
                  transition: 'transform 0.2s',
                  transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            ) : (
              <IconUserAnalytics
                viewBox="2 2 20 20"
                width="100%"
                height="100%"
              />
            )}
          </Box>
        }
        variant="ghost"
        rightIcon={
          isOpen ? undefined : (
            <Badge
              color="amethyst.prior"
              background="amethyst.extraLight"
              fontSize="memo1"
              px="6px"
              py="2px"
              rounded="lg"
            >
              {count}
            </Badge>
          )
        }
        fontWeight="bold"
      >
        {t('userAnalyticsProjects')}
        <BetaFeatureTag ml="4px" />
      </Button>
      {isOpen &&
        uaProjects.map((p, i) =>
          p.__typename === 'UserAnalyticsProject' ? (
            <UserAnalyticsProject
              key={p.id}
              project={p}
              showTourTooltip={i === 0}
            />
          ) : null
        )}
      {isOpen && uaProjects.length === 0 && (
        <Box p="2" fontSize="text2" color="color.hambleA11y">
          {t('noUserAnalyticsProjects')}
        </Box>
      )}
    </Stack>
  );
};

const UserAnalyticsProject = ({
  project,
}: {
  project: SideNavigationOrgFragment['userAnalyticsProjects'][number];
  showTourTooltip?: boolean;
}) => {
  const {orgDatabaseId} = useQueryParamOrgId();
  const [isHover, setIsHover] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure({
    defaultIsOpen: false,
  });
  const router = useRouter();
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});

  if (!orgDatabaseId) return null;
  if (project.__typename !== 'UserAnalyticsProject') return null;

  const pushToProject = () =>
    router.push(
      pagesPath.orgs
        ._orgId(orgDatabaseId)
        .projects._projectId(decodeId(project.id).docId)
        .user_analytics.$url()
    );

  const projectDatabaseId = decodeId(project.id).docId;
  const userAnalyticsDatabaseId = project.userAnalytics?.id
    ? decodeId(project.userAnalytics.id).docId
    : undefined;

  return (
    <Stack spacing="0">
      <Button
        size="sm"
        as="div" // prevent nesting interactive elements
        tabIndex={0}
        role="button"
        key={project.id}
        variant="ghost"
        justifyContent="flex-start"
        w="100%"
        aria-current={
          router.pathname ===
            pagesPath.orgs
              ._orgId(orgDatabaseId)
              .projects._projectId(projectDatabaseId)
              .user_analytics.$url().pathname &&
          router.query.projectId === projectDatabaseId
            ? true
            : undefined
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onKeyDown={e => {
          // space: toggle open/close
          if (e.key === ' ') {
            e.preventDefault();
            isOpen ? onClose() : onOpen();
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            onOpen();
            pushToProject();
          }
        }}
        onClick={e => {
          e.preventDefault();
          onOpen();
          pushToProject();
        }}
        leftIcon={
          <Box
            w="18px"
            h="18px"
            rounded="sm"
            backgroundColor={isHover ? '' : project.color}
            _hover={{
              background: '#D9D9D9',
            }}
            onClick={e => {
              // open/close on click icon
              e.preventDefault();
              e.stopPropagation();
              isOpen ? onClose() : onOpen();
            }}
          >
            {isHover ? (
              <MdArrowRight
                size="18px"
                style={{
                  transition: 'transform 0.2s',
                  transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
              />
            ) : null}
          </Box>
        }
      >
        {project.name}
      </Button>
      <HStack spacing="2px">
        <Divider orientation="vertical" ml="20px" />
        <Stack spacing="0" overflow="hidden" flex="1" p="2px" m="-2px">
          {isOpen && userAnalyticsDatabaseId && (
            <>
              <SessionExplorerLink
                projectDatabaseId={projectDatabaseId}
                orgDatabaseId={orgDatabaseId}
              />

              <UrlGroupLink
                projectDatabaseId={projectDatabaseId}
                orgDatabaseId={orgDatabaseId}
              />

              <ViewLink
                projectDatabaseId={projectDatabaseId}
                orgDatabaseId={orgDatabaseId}
                userAnalyticsDatabaseId={userAnalyticsDatabaseId}
                viewDatabaseId="default"
                viewName={t('(all)')}
              />

              {project.userAnalytics?.views?.map(view => (
                <ViewLink
                  key={view.id}
                  projectDatabaseId={projectDatabaseId}
                  orgDatabaseId={orgDatabaseId}
                  userAnalyticsDatabaseId={userAnalyticsDatabaseId}
                  viewDatabaseId={decodeId(view.id).docId}
                  viewName={view.name}
                />
              ))}
            </>
          )}
          {isOpen && !project.userAnalytics && (
            <Box p="2" fontSize="text2" color="color.hambleA11y">
              {t('noUserAnalyticsData')}
            </Box>
          )}
        </Stack>
      </HStack>
    </Stack>
  );
};

const SessionExplorerLink = ({
  projectDatabaseId,
  orgDatabaseId,
}: {
  projectDatabaseId: string;
  orgDatabaseId: string;
}) => {
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const router = useRouter();
  return (
    <NextLink
      legacyBehavior
      href={pagesPath.orgs
        ._orgId(orgDatabaseId)
        .projects._projectId(projectDatabaseId)
        .user_analytics.session_explorer.$url()}
      passHref
    >
      <Button
        as="a"
        size="sm"
        variant="ghost"
        justifyContent="flex-start"
        leftIcon={
          <IconSessionExplorer width="18px" height="18px" viewBox="2 2 20 20" />
        }
        aria-current={
          router.pathname ===
            pagesPath.orgs
              ._orgId(orgDatabaseId)
              .projects._projectId(projectDatabaseId)
              .user_analytics.session_explorer.$url().pathname &&
          router.query.projectId === projectDatabaseId
            ? true
            : undefined
        }
      >
        {t('sessionExplorer')}
      </Button>
    </NextLink>
  );
};

const UrlGroupLink = ({
  projectDatabaseId,
  orgDatabaseId,
}: {
  projectDatabaseId: string;
  orgDatabaseId: string;
}) => {
  const {t} = useTranslation('layout', {keyPrefix: 'SideNavigation'});
  const router = useRouter();
  return (
    <NextLink
      legacyBehavior
      href={pagesPath.orgs
        ._orgId(orgDatabaseId)
        .projects._projectId(projectDatabaseId)
        .user_analytics.url_group.$url()}
      passHref
    >
      <Button
        as="a"
        size="sm"
        variant="ghost"
        justifyContent="flex-start"
        leftIcon={<MdLink size="18px" />}
        aria-current={
          router.pathname ===
            pagesPath.orgs
              ._orgId(orgDatabaseId)
              .projects._projectId(projectDatabaseId)
              .user_analytics.url_group.$url().pathname &&
          router.query.projectId === projectDatabaseId
            ? true
            : undefined
        }
      >
        {t('urlGroup')}
      </Button>
    </NextLink>
  );
};

const ViewLink = ({
  projectDatabaseId,
  orgDatabaseId,
  userAnalyticsDatabaseId,
  viewDatabaseId,
  viewName,
}: {
  projectDatabaseId: string;
  orgDatabaseId: string;
  userAnalyticsDatabaseId: string;
  viewDatabaseId: string;
  viewName: string;
}) => {
  const router = useRouter();
  const isActive =
    router.pathname ===
      pagesPath.orgs
        ._orgId(orgDatabaseId)
        .projects._projectId(projectDatabaseId)
        .user_analytics._userAnalyticsId(userAnalyticsDatabaseId)
        .views._viewId(viewDatabaseId)
        .$url().pathname &&
    router.query.projectId === projectDatabaseId &&
    router.query.viewId === viewDatabaseId;

  return (
    <NextLink
      legacyBehavior
      href={pagesPath.orgs
        ._orgId(orgDatabaseId)
        .projects._projectId(projectDatabaseId)
        .user_analytics._userAnalyticsId(userAnalyticsDatabaseId)
        .views._viewId(viewDatabaseId)
        .$url()}
      passHref
    >
      <Button
        as="a"
        size="sm"
        variant="ghost"
        justifyContent="flex-start"
        aria-current={isActive ? true : undefined}
        leftIcon={
          <IconUserAnalytics width="18px" height="18px" viewBox="2 2 20 20" />
        }
      >
        {viewName}
      </Button>
    </NextLink>
  );
};
